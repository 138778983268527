import React from "react";

const Products = () => {
	return (
		<div className="row bg-transparent d-flex justify-content-around m-2">
			<div className="col-md-6 bg-transparent my-1">
				<div className="card rounded">
					<div className="card-header">
						<p className="h5 card-title">
							School/College Management Software System
						</p>
					</div>
					<div className="card-body d-flex justify-content-center">
						<iframe
							width="560"
							height="315"
							src="https://www.youtube.com/embed/Tn81LZLFTeU?si=jw6aO9NNO_uqG1WZ"
							title="SCHOOL OR COLLEGE SOFTWARE MANAGEMENT SYSTEM"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
						></iframe>
					</div>

					<div className="card-footer d-flex justify-content-around">
						<a
							download
							href="https://dl.dropbox.com/scl/fi/fmj43wi6vj2o6b4bjytir/School-Techsystem-6.0.zip?rlkey=j1oc1t1b5fri66celu45zjukr&st=6yt3afee&dl=0"
						>
							<button className="btn btn-outline-primary rounded">
								Download Software (Offline & Online)
							</button>
						</a>
						<a download href="https://school.techsystem.world">
							<button className="btn btn-outline-primary rounded">
								Use PWA app (Online Only)
							</button>
						</a>
					</div>
				</div>
			</div>
			<div className="col-md-6 bg-transparent my-1">
				<div className="card rounded">
					<div className="card-header">
						<p className="h5 card-title">
							Hospital/Clinic Management Software System
						</p>
					</div>
					<div className="card-body d-flex justify-content-center">
						<iframe
							width="560"
							height="315"
							src="https://www.youtube.com/embed/cXuDPkJnX6k?si=RXiRproi2IO2F8kP"
							title="Hospital/Clinic Management Software System"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
						></iframe>
					</div>
					<div className="card-footer d-flex justify-content-around">
						{" "}
						<a
							download
							href="https://dl.dropbox.com/scl/fi/c0q71nexs4qy9svyvndf1/Health-Techsystem-6.0.zip?rlkey=r8o6l9wnsurj9xk85i5rn87rt&st=6dljly84&dl=0"
						>
							<button className="btn btn-outline-primary rounded">
								Download Software (Offline & Online)
							</button>
						</a>
						<a download href="https://health.techsystem.world">
							<button className="btn btn-outline-primary rounded">
								Use PWA app (Online Only)
							</button>
						</a>
					</div>
				</div>
			</div>
			<div className="col-md-6 bg-transparent my-1">
				<div className="card rounded">
					<div className="card-header">
						<p className="h5 card-title">
							Sacco/Microfinance Management Software System
						</p>
					</div>
					<div className="card-body d-flex justify-content-center">
						{/*<video
							style={{ width: "100%", height: "auto" }}
							controls
							autoPlay={false}
							loop
							muted
							poster="https://www.dropbox.com/scl/fi/my1uu9lwyxrnsbogxfrwh/fb-finance.jpg?rlkey=rlh8ky1fzwbtn98g933v4mo49&raw=1"
						>
							<source
								src="https://www.dropbox.com/scl/fi/106seo3eugmgvp10d7p44/SACCO-or-Microfinance-self-help-groups-savings-loan-credit-software-management-system.mp4?rlkey=x5y6c40y1ma1yqc8cpqnrha5o&raw=1"
								type="video/mp4"
							></source>
						</video>*/}
						<iframe
							width="560"
							height="315"
							src="https://www.youtube.com/embed/H4zUoacM3dk?si=6PIXsxVifwY-zc8v"
							title="SACCO OR MICROFINANACE SOFTWARE MANAGEMENT SYSTENM"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
						></iframe>
					</div>

					<div className="card-footer d-flex justify-content-around">
						{" "}
						<a
							download
							href="https://dl.dropbox.com/scl/fi/kur0uqzs3p73d4kbvrdsb/Finance-Techsystem-6.0.zip?rlkey=5fsniomq1owtbkxi08bz9mx9a&st=sz9btiwi&dl=0"
						>
							<button className="btn btn-outline-primary rounded">
								Download Software (Offline & Online)
							</button>
						</a>
						<a download href="https://finance.techsystem.world">
							<button className="btn btn-outline-primary rounded">
								Use PWA app (Online Only)
							</button>
						</a>
					</div>
				</div>
			</div>
			<div className="col-md-6 bg-transparent my-1">
				<div className="card rounded">
					<div className="card-header">
						<p className="h5 card-title">
							Rentals/Apartments/Hostels Management Software System
						</p>
					</div>
					<div className="card-body d-flex justify-content-center">
						<iframe
							width="560"
							height="315"
							src="https://www.youtube.com/embed/eyBO9JE9Vjw?si=O-sb6M3ykrPgS5Tf"
							title="Rentals/Apartments/Hostels Management Software System"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
						></iframe>
					</div>
					<div className="card-footer d-flex justify-content-around">
						{" "}
						<a
							download
							href="https://dl.dropbox.com/scl/fi/m82wpaqhp5xkeea4ezg96/Rental-Techsystem-6.0.zip?rlkey=ue804vwa447wc6bfy1rru91pk&st=nzlsdu7n&dl=0"
						>
							<button className="btn btn-outline-primary rounded">
								Download Software (Offline & Online)
							</button>
						</a>
						<a download href="https://rental.techsystem.world">
							<button className="btn btn-outline-primary rounded">
								Use PWA app (Online Only)
							</button>
						</a>
					</div>
				</div>
			</div>
			<div className="col-md-6 bg-transparent my-1">
				<div className="card rounded">
					<div className="card-header">
						<p className="h5 card-title">
							Business And Point Of Sale Management Software System
						</p>
					</div>
					<div className="card-body d-flex justify-content-center">
						<iframe
							width="560"
							height="315"
							src="https://www.youtube.com/embed/qU21mhVnQ2M?si=uotmDNEreItxZd0Z"
							title="Business And Point Of Sale Management Software System"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
						></iframe>
					</div>
					<div className="card-footer d-flex justify-content-around">
						{" "}
						<a
							download
							href="https://dl.dropbox.com/scl/fi/ydb3n60pg64hm0v26h9hl/Business-Techsystem-6.0.zip?rlkey=pokmprm588rr5z3356gkfpdx3&st=yaj3f5c2&dl=0"
						>
							<button className="btn btn-outline-primary rounded">
								Download Software (Offline & Online)
							</button>
						</a>
						<a download href="https://business.techsystem.world">
							<button className="btn btn-outline-primary rounded">
								Use PWA app (Online Only)
							</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Products;
